export const getCookie = (name: string): string | undefined =>
  document.cookie.match(`${name}=([^;]*)`)?.[1];

export type CookieOptions = {
  path?: string; // defaults to the current path of the current location
  domain?: string; // defaults to the host portion of the current location
  "max-age"?: number; // in seconds
  expires?: string; // a GMT date string
  secure?: boolean;
  samesite?: "lax" | "strict" | "none";
};

// we only actually support these options at time of writing
export type SupportedCookieOptions = Pick<
  CookieOptions,
  "max-age" | "samesite" | "secure"
>;

export const setCookie = (
  name: string,
  value: string,
  opts: SupportedCookieOptions
): string =>
  (document.cookie = [
    `${name}=${value}`,
    `max-age=${opts["max-age"]}`,
    `samesite=${opts.samesite}`,
    ...(opts.secure ? ["secure", "domain=.dndbeyond.com", "path=/"] : []),
  ].join(";"));

export const deleteCookie = (name: string): string =>
  setCookie(name, "", { "max-age": 0 });
