export type BannerConfig = {
  disclaimerText: string;
  learnMoreHref: string;
  learnMoreText: string;
  grantText: string;
  denyText: string;
};

const config: BannerConfig = {
  disclaimerText: [
    "We use necessary cookies to allow our site to function correctly and",
    "collect anonymous session data. Necessary cookies can be opted out",
    "through your browser settings. We also use optional cookies to",
    "personalize content and ads, provide social medial features and analyze",
    'web traffic. By clicking "OK, I agree", you consent to optional cookies.',
  ].join(" "),

  learnMoreHref: "https://www.dndbeyond.com/cookies",
  learnMoreText: "Learn more about cookies.",

  grantText: "OK, I agree",
  denyText: "No, thanks",
};

/**
 * this is wrapped in a promise to simulate fetching the config from a
 * localization endpoint
 *
 * @returns {Promise<BannerConfig>}
 */
export const getConfig = (): Promise<BannerConfig> => Promise.resolve(config);
