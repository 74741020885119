import { getConfig, BannerConfig } from "./config";

/**
 * special note about the z-index here, we are trying to beat these numbers in
 * the dice lib and on character tools ... there's a loading blocker that comes
 * in at 60100, but it appears to be in a separate stacking context, so this
 * meager 60003 still "wins"
 *
 * @see https://github.com/DnDBeyond/ddb-integrated-dice/blob/master/packages/ddb-dice-test-views/src/css/styles.scss#L46
 * @see https://github.com/DnDBeyond/ddb-characters/blob/main/apps/character-app/src/tools/styles/character-tools/sheet/_components/_quick-nav.scss#L7
 * @see https://github.com/DnDBeyond/ddb-characters/blob/main/apps/character-app/src/tools/styles/character-tools/sheet/_components/_loading-blocker.scss#L6
 */
const generateMarkup = (
  id: string,
  {
    disclaimerText,
    learnMoreHref,
    learnMoreText,
    grantText,
    denyText,
  }: BannerConfig
) => `\
<div id="${id}">
  <style>
    #${id} {
      color: #fff;
      background-color: rgba(0, 0, 0, 0.9);

      position: fixed;
      right: 0;
      bottom: 0;
      left: 0;

      z-index: 60003;

      padding: 2rem;

      font-family: sans-serif;

      transform: translateY(100%);
      opacity: 0;
      
      transition:
        transform 0.5s cubic-bezier(0.075,0.82,0.165,1) 0.1s,
        opacity 0.5s cubic-bezier(0.075,0.82,0.165,1) 0.1s;
    }
    #${id}.show {
      transform: translateY(0%);
      opacity: 1;
    }

    #${id} > div { max-width: 1080px; margin: 0 auto; }

    #${id} > div,
    #${id} menu {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    #${id} p {
      margin-top: 0;
    }

    #${id} menu {
      flex: 1 0 auto;

      font-size: 13px;

      list-style: none;
      margin: 0;
      padding: 0;
    }

    #${id} li {
      margin-left: 2em;
    }

    #${id} button {
      padding: 1em 1.5em;

      display: inline-flex;
      align-items: center;
      justify-content: center;

      color: #fff;
      letter-spacing: 2px;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 1;

      border: 2px solid #e61720;
      background-color: #9e0b0f;
      background-image: linear-gradient(90deg, #9e0b0f, #ce1016);
      clip-path: polygon(0 0,100% 0,100% calc(100% - 16px),calc(100% - 16px) 100%,0 100%);

      cursor: pointer;
    }
    #${id} button:hover { filter: brightness(110%); }

    #${id} a { color: #9e0b0f; text-decoration: none; }
    #${id} a:hover { text-decoration: underline; }

    @media (max-width: 1023px) {
      #${id} > div { flex-direction: column; }
      #${id} li:first-of-type { margin-left: 0; }
    }
  </style>
  <div>
    <p>
    ${disclaimerText}
    (<a href="${learnMoreHref}" target="_blank">${learnMoreText}</a>)
    </p>
    <menu>
      <li><button id="cookie-consent-granted">${grantText}</button></li>
      <li><button id="cookie-consent-denied">${denyText}</button></li>
    </menu>
  </div>
</div>
`;

const documentReady = () =>
  new Promise<Event | void>((resolve) =>
    /**
     * if `document.readyState` is `"interactive"` or `"complete"` then
     * `"DOMContentLoaded"` may have already fired, and in either case the
     * document should be *ready enough*
     */
    document.readyState === "loading"
      ? document.addEventListener("DOMContentLoaded", resolve, { once: true })
      : resolve()
  );

const insertMarkup = (el: Element, markup: string): void =>
  el.insertAdjacentHTML(
    /**
     * It's been a while, so: "Just inside the element, after its last child."
     * @see https://developer.mozilla.org/en-US/docs/Web/API/Element/insertAdjacentHTML
     */
    "beforeend",
    markup
  );

export const showBanner = async (
  onConsentGranted: () => void,
  onConsentDenied: () => void
): Promise<void> => {
  /**
   * await both `config` and `document.readyState` in parallel, this async
   * script tag is loaded in the head and may be served from an HTTP cache in
   * which case executes before the document is parsed (and `document.body` is
   * `null` or `undefined`)
   */
  const [config] = await Promise.all([getConfig(), documentReady()]);

  // generate banner markup and insert it into the DOM
  const id = `cookie-consent-banner-${Date.now()}`;
  insertMarkup(document.body, generateMarkup(id, config));

  // get the banner element and show it by adding the "show" class
  const banner = document.getElementById(id);
  setTimeout(() => banner.classList.add("show"), 0);

  // reverse the "show" transition, and remove the banner element
  const removeBanner = () => {
    banner.classList.remove("show");
    setTimeout(() => banner.remove(), 600); // 0.5s + 0.1s (see CSS above)
  };

  document.getElementById("cookie-consent-granted")?.addEventListener(
    "click",
    () => {
      onConsentGranted();
      removeBanner();
    },
    { once: true }
  );

  document.getElementById("cookie-consent-denied")?.addEventListener(
    "click",
    () => {
      onConsentDenied();
      removeBanner();
    },
    { once: true }
  );
};
