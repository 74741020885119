import {
  CONSENT_COOKIE_NAME,
  CookieConsent,
  getMaxAge,
} from "@dndbeyond/cookie-consent-shared";
import { showBanner } from "./lib/banner";
import {
  deleteCookie,
  getCookie,
  setCookie,
  SupportedCookieOptions,
} from "./lib/cookie";

declare global {
  var dataLayer: any[] | undefined;
}

if (process.env.NODE_ENV === "development") {
  deleteCookie(CONSENT_COOKIE_NAME);
}

const setCookieConsent = (consent: CookieConsent): void => {
  const cookieOpts: SupportedCookieOptions = {
    "max-age": getMaxAge(consent),
    samesite: "lax",
    secure: location.protocol === "https:",
  };

  // Set cookie locally in case the below API is ever down.
  setCookie(CONSENT_COOKIE_NAME, consent, cookieOpts);

  // Fetch cookie from server in order to extend the max-age greater than 7 days.
  // This should replace the cookie set in the previous call.
  const request = fetch(`${process.env.API_ORIGIN}?value=${consent}`, {
    credentials: "include",
  });

  if (process.env.NODE_ENV === "development") {
    request
      .then((response) => response.text())
      .then((text) => console.log(text));
  }

  /**
   * this hooks into GTM's "Data Layer" mechanism to trigger tags when someone
   * arrives at dndbeyond.com without a cookie-consent cookie, and clicks the
   * banner for the first time (e.g. if they *just* gave consent, we wanna fire
   * those tags right away and not wait for a page reload or navigation event)
   */
  (window.dataLayer || (window.dataLayer = [])).push({
    event: "cookie-consent",
    "cookie-consent": consent,
  });
};

if (getCookie(CONSENT_COOKIE_NAME) === undefined) {
  showBanner(
    () => setCookieConsent(CookieConsent.Granted),
    () => setCookieConsent(CookieConsent.Denied)
  );
}
